import axios from 'redaxios';
import { IPhotoVideoAnonymousCollection } from 'types/photoVideoAPI';

export async function getPhotoVideo({
  collectionId,
}: {
  collectionId: number;
}): Promise<IPhotoVideoAnonymousCollection> {
  const response = await axios.get(
    `https://photovideoapi.buienradar.nl/api/newzulu/media/AnonymousGetCollectionFiles/${collectionId}/0/6`
  );
  return response.data;
}
