import AdSlot, { BannerType } from 'components/AdSlot';
import Heading from 'components/Heading/Heading';
import TextLink from 'components/TextLink/TextLink';
import Text from 'components/Text/Text';
import { BottomSettings } from 'types/siteAPI';

import cssVariables from 'styles/variables.module.scss';
import styles from './SocialPictures.module.scss';

function IframeAdvertorialView({ settings }: { settings: BottomSettings }) {
	const { title, src, width, height, link, linkLabel } =
		settings.advertorialiframe;
	return (
		<div>
			{title ? <Heading variant="h3">{title}</Heading> : null}
			<iframe
				title={title}
				data-testid="advertorial-iframe"
				src={src}
				width={width}
				height={height}
				frameBorder="0"
			/>
			{link && linkLabel ? (
				<TextLink appearance="text" href={link}>
					{linkLabel}
				</TextLink>
			) : null}
		</div>
	);
}
function RectangleAdvertorialView() {
	return <AdSlot type={BannerType.Rectangle} id="br-ad-home-social" />;
}
function DefaultAdvertorialView({ settings }: { settings: BottomSettings }) {
	const { advertorial } = settings;
	if (!advertorial) return null;
	const { title, link, linkLabel, thumbnail, description } = advertorial;
	return (
		<div className={styles.defaultAdvertorialContainer}>
			<Heading variant="h2" classNameExt={styles.heading}>
				{title}
			</Heading>
			<a
				href={link}
				className={styles.advertorialImageLink}
				title={title}
			>
				<picture>
					<source
						srcSet={thumbnail}
						media={cssVariables.desktopS}
						height="170"
						width="336"
					/>
					<source
						srcSet={thumbnail}
						media={cssVariables.mobileM}
						height="280"
						width="336"
					/>
					<img
						src={thumbnail}
						alt={title}
						height="160"
						width="336"
						className={styles.advertorialImage}
						loading="lazy"
					/>
				</picture>
			</a>
			<Text>{description}</Text>
			<TextLink href={link} appearance="text">
				{linkLabel}
			</TextLink>
		</div>
	);
}

const AdvertorialViews = {
	iframe: IframeAdvertorialView,
	rectangle: RectangleAdvertorialView,
	default: DefaultAdvertorialView,
};

export function AdvertorialPosition({
	settings,
}: {
	settings: BottomSettings;
}) {
	const hasRectangleAd =
		settings.dynamicAdvertorial && settings.dynamicAdvertorial.visible;
	const hasIframeAd =
		settings.advertorialiframe && settings.advertorialiframe.visible;
	const advertorialType = hasRectangleAd
		? 'rectangle'
		: hasIframeAd
		? 'iframe'
		: 'default';
	const AdvertorialView = AdvertorialViews[advertorialType];

	return (
		<section className={styles.advertorialContainer}>
			<AdvertorialView settings={settings} />
		</section>
	);
}
