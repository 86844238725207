import Heading from 'components/Heading/Heading';
import TextLink from 'components/TextLink/TextLink';
import getConfig from 'next/config';
import { useQuery } from 'react-query';
import { Bottom } from 'types/siteAPI';
import { getPhotoVideo } from 'utils/getPhotoVideo';
import { AdvertorialPosition } from './SocialPictures.Advertorial';

import cssVariables from 'styles/variables.module.scss';
import styles from './SocialPictures.module.scss';

export default function SocialPictures({ content }: { content: Bottom }) {
	const { settings } = content;
	if (!settings) return null;
	const { publicRuntimeConfig } = getConfig();
	const { photoVideoEnabled } = publicRuntimeConfig;

	return (
		<section className={styles.socialPicturesContainer}>
			<AdvertorialPosition settings={settings} />
			{photoVideoEnabled &&
			settings.collectionId &&
			settings.pictureLink ? (
				<SocialPicturesDisplay
					collectionId={settings.collectionId}
					pictureLink={settings.pictureLink}
				/>
			) : null}
		</section>
	);
}

function SocialPicturesDisplay({
	collectionId,
	pictureLink,
}: {
	collectionId: number;
	pictureLink: string;
}) {
	const { data, isError, isLoading } = useQuery(
		['photoVideoCollection', collectionId],
		() => getPhotoVideo({ collectionId }),
		{
			enabled: collectionId !== null,
		}
	);

	if (isError || isLoading) return null;
	if (!data?.status) return null;

	//Always point to https://www.buienradar.nl since the 'photo-video' environment does not exist on Belgium part
	pictureLink = pictureLink.startsWith('https://')
		? pictureLink
		: `https://www.buienradar.nl${pictureLink}`;

	return (
		<section className={styles.socialPictures}>
			<Heading variant="h2" classNameExt={styles.heading}>
				Weerfoto&apos;s
			</Heading>
			<a
				href={pictureLink}
				className={styles.socialPicturesGrid}
				title="Bekijk alle foto's"
			>
				{data.result.data.map((item) => {
					return (
						<picture key={item.id}>
							<source
								srcSet={`${item.thumbUrl}/13`}
								media={cssVariables.desktopS}
								width="170"
								height="113"
							/>
							<img
								src={`${item.thumbUrl}/14`}
								alt={item.title}
								width="236"
								height="113"
								loading="lazy"
							/>
						</picture>
					);
				})}
			</a>
			<TextLink appearance="text" href={pictureLink}>
				Bekijk alle foto&apos;s
			</TextLink>
		</section>
	);
}
